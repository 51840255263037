import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile } from 'styles/breakpoints';
import theme from 'utils/theme';

interface CheckboxProps {
  checked: boolean;
  onChange: () => void;
  quizVariant: any;
  isRound: boolean;
}

const Checkbox: FC<CheckboxProps> = ({
  checked,
  onChange,
  quizVariant,
  isRound,
  ...props
}) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} onChange={onChange} {...props} />
    {quizVariant ? (
      <StyledCheckboxQuiz checked={checked} isRound={isRound}>
        <IconQuiz checked={checked} viewBox="0 0 16 13">
          <path d="M1 6L7 11L15 1" stroke="#213370" strokeWidth="2.3" />
        </IconQuiz>
      </StyledCheckboxQuiz>
    ) : (
      <StyledCheckbox checked={checked} isRound={isRound}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    )}
  </CheckboxContainer>
);

export default Checkbox;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 1.90476px;
  margin-bottom: 1px;
`;

const IconQuiz = styled.svg<{ checked: boolean }>`
  fill: none;
  stroke: #fff;
  display: ${({ checked }) => (checked ? 'block' : 'none')};
  height: 13px;
  width: 16px;

  @media ${mobile} {
    height: 9px;
    width: 11px;
  }
`;

const StyledCheckbox = styled.div<{ checked: boolean; isRound?: boolean }>`
  display: inline-block;
  width: 1.42856rem;
  height: 1.42856rem;
  background: ${({ checked, theme }) =>
    checked ? theme.colors.white : theme.colors.white};
  border: 1px solid
    ${({ checked, theme }) =>
      checked ? theme.colors.mgSecondary100 : theme.colors.lighterGrey};
  border-radius: ${({ isRound }) => (isRound ? '10rem' : '0.5rem')};
  transition: all 150ms;

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }

  @media ${mobile} {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const StyledCheckboxQuiz = styled.div<{ checked: boolean; isRound?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  background: ${({ checked, theme }) =>
    checked ? theme.colors.white : theme.colors.white};
  border: 1px solid
    ${({ checked, theme }) =>
      checked ? theme.colors.mgSecondary100 : theme.colors.lighterGrey};
  border-radius: ${({ isRound }) => (isRound ? '10rem' : '0.5rem')};
  position: relative; // <-- Add this line

  ${Icon} {
    visibility: visible;
  }

  @media ${mobile} {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const CheckboxContainer = styled.div`
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
`;
