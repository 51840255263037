import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { DefaultTheme, keyframes } from 'styled-components';
import { Question, QuizAnswer, Quiz as QuizType } from 'types/quiz';
import { DynamicImage, Text } from 'components';
import {
  desktop,
  mobile,
  sxMobile,
  tablet,
  useQuery,
} from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import LCFooter from 'components/LCFooter';
import socProof from 'assets/images/landing/mobile_landing.png';
import socProof_des from 'assets/images/landing/desktop_landing.png';
import Logo from 'assets/icons/logo/logo.svg';
import BlackFridayBanner from 'components/BlackFridayBanner';
import { useFirstVisitDate, usePageView, useScrollToTop } from 'utils/hooks';
import BlackFridayLandingLabel from './BlackFridayLandingLabel';
import Tracking from 'utils/tracking';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { updateQuizAnswers, updateQuizFlowAnswers } from 'state/user/effects';
import { resetQuizAnswers } from 'state/user/actions';
import { useLocation } from '@reach/router';
import { normalizeStates } from 'utils/localization';
import Header from 'components/reviews/Header';
import PRFooter from 'components/PRFooter';

import MaleButtonLogo from '../../../../assets/icons/mg/male-landing.svg';
import FemaleButtonLogo from '../../../../assets/icons/mg/female-landing.svg';
import BackgroundImage from '../../../../assets/images/quiz/background/zodiac.svg';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
  hideFooter?: boolean;
}

interface ButtonsContainerProps {
  length: number;
}

const Landing: FC<LandingProps> = ({
  question,
  onOptionSelect,
  hideFooter = false,
}) => {
  // const { isTablet } = useQuery();
  // const { countdownDiffInMS } = useFirstVisitDate();
  const location = useLocation();
  const questionParam = new URLSearchParams(location.search).get('q');
  const bfBannerExists = question?.bfBanner;
  // const quiz_flow_answers = useSelector(
  //   (state: AppState) => state.user?.quiz_flow_answers,
  // );
  // const isBrowser = typeof window !== 'undefined';
  // const quiz_answers = useSelector(
  //   (state: AppState) => state.user?.quiz_answers,
  // );
  const quiz = new URLSearchParams(location.search).get('qz') ?? QuizType.Main;

  // const geo = useSelector((state: AppState) => state.user?.geolocation);
  // const dispatch = useDispatch();
  const isAddsFlow =
    location.pathname.includes('start') || quiz !== QuizType.Main;

  // usePageView({
  //   city: encodeURIComponent(
  //     geo?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
  //   ),
  //   country: geo?.iso_country?.toLowerCase(),
  //   state: normalizeStates(geo?.iso_country || '', geo?.iso_state || ''),
  //   gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
  // });

  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.href, location.pathname]);

  const pickSocialProfImage = (variant?: string) =>
    // if (variant === 'blackFriday') {
    //   return {
    //     mob: bfSocProof,
    //     des: bfSocProof_des,
    //   };
    // }
    // if (variant === 'xmas') {
    //   return {
    //     mob: xmasSocProof,
    //     des: xmasSocProof_des,
    //   };
    // }
    // if (variant === 'ny') {
    //   return {
    //     mob: nySocProof,
    //     des: nySocProof_des,
    //   };
    // }
    ({
      mob: socProof,
      des: socProof_des,
    });
  const handleOptionsSelect = (item: any) => {
    const logEvent = (data: { category: string; label?: string }) => {
      Tracking.trackQuizQuestionAnswered({
        event: `Quiz question answered - 1`,
        category: `1 - ${data.category}`,
        label: data.label || '',
      });
    };

    logEvent({
      category: `${question.label}${
        question.optionsLabel ? ` - ${question.optionsLabel}` : ''
      }`,
      label: String(item?.label),
    });

    onOptionSelect({
      [question?.key]: item?.gender,
      label: item?.label,
    });
  };

  const renderGenderButtons = (options: any[]) => (
    <>
      <GenderButtonContainer>
        {options?.map((option, index) => (
          <GenderButton
            key={index}
            activeBorder={option.activeBorderColor}
            activeBackground={option.activeBackgroundColor}
            onClick={() =>
              handleOptionsSelect({
                [question?.key]: option?.value,
                label: option?.label,
              })
            }
          >
            {option.value === 'male' ? (
              <MaleButtonLogo />
            ) : (
              <FemaleButtonLogo />
            )}
            <span>{option?.label}</span>
          </GenderButton>
        ))}
      </GenderButtonContainer>
    </>
  );

  const renderGenderBasic = (options: any[]) => {
    if (!options.length) {
      return null;
    }
    return options?.map((option, index) => {
      const hasColor = !!option.color;
      return (
        <OtherGenderButton
          onClick={() =>
            handleOptionsSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          key={index}
        >
          {hasColor ? (
            option?.label
          ) : (
            <Text type="body500">{option?.label}</Text>
          )}
        </OtherGenderButton>
      );
    });
  };

  const renderGenderOptions = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsContainer
        length={[...primaryOptions, ...secondaryOptions].length}
      >
        {renderGenderButtons(primaryOptions)}
        {renderGenderBasic(secondaryOptions)}
      </ButtonsContainer>
    );
  };

  useScrollToTop(0);

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <>
      <Container hideFooter={hideFooter} backgroundImage={BackgroundImage.src}>
        {/* {bfBannerExists ? (
          <BlackFridayBannerStyled
            {...question.bfBanner}
            countdownDiffInMS={countdownDiffInMS}
          />
        ) : null} */}
        {!isAddsFlow ? (
          <Header isMobileApp={false} ref={headerRef} />
        ) : (
          <Header isMobileApp={true} />
        )}

        <BackgroundImageContainer>
          <BackgroundImage />
        </BackgroundImageContainer>
        <Wrapper isFullHeight={isAddsFlow}>
          <WrappedItem>
            <LandingContainer>
              <LandingTextContainer>
                <TitleContainer>
                  <Title
                    textAlign={'center'}
                    type="h1"
                    dangerouslySetInnerHTML={{ __html: question?.title || '' }}
                  />
                </TitleContainer>
                <DescriptionContainer>
                  <Label
                    textAlign={'center'}
                    type="h2"
                    dangerouslySetInnerHTML={{
                      __html: question?.description || '',
                    }}
                    descriptionIsMaxWidth={question?.descriptionIsMaxWidth}
                  />
                </DescriptionContainer>
              </LandingTextContainer>
              <GenderContainer>
                <ButtonsTitleContainer>
                  <ButtonsLabel
                    dangerouslySetInnerHTML={{ __html: question?.label }}
                    textAlign={'center'}
                  />
                </ButtonsTitleContainer>
                {renderGenderOptions()}
                {question?.bfLabel ? (
                  <BlackFridayLandingLabel {...question.bfLabel} />
                ) : null}
              </GenderContainer>
            </LandingContainer>
          </WrappedItem>
        </Wrapper>
        <FooterContainer>
          {!isAddsFlow ? (
            <LCFooter
              disclaimerText={question?.disclaimerParagraphs || []}
              whiteFooter
            />
          ) : (
            <PRFooter disclaimerText={question?.disclaimerParagraphsPR || []} />
          )}
        </FooterContainer>
      </Container>
    </>
  );
};

export default Landing;

const Container = styled.div<{
  hideFooter?: boolean;
  backgroundImage?: string;
}>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f6f6f6;
  background-image: ${({ backgroundImage }) => backgroundImage};
`;

const WrappedItem = styled.div`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.main<{ isFullHeight?: boolean }>`
  position: relative;
  height: 100%;
`;

const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  justify-content: center;
  @media ${tablet} {
  }
`;

const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  font-family: "Afacad", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 2.3rem */
  @media ${tablet} {
    font-size: 1.875rem;
  }
`;

const Label = styled(Text)<{
  descriptionIsMaxWidth?: boolean;
  fontSizing?: number;
}>`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  font-family: "Fustat", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const ButtonsLabel = styled(Text)`
  color: #000;
  font-family: "Fustat", sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  @media ${mobile} {
    font-weight: 800;
  }
`;

const DescriptionContainer = styled.div``;

const TitleContainer = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;

  @media ${tablet} {
  }
`;

const GenderButton = styled.button<{
  variant?: string;
  activeBorder: keyof DefaultTheme['colors'];
  activeBackground: keyof DefaultTheme['colors'];
}>`
  cursor: pointer;
  display: flex;
  width: 13.41319rem;
  height: 13.41319rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.34131rem;
  border-radius: 0.75rem;
  border: 1px solid #dadada;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  transition: background 100ms ease-in, border-color 100ms ease-in;
  font-size: 1.33331rem;
  font-weight: 600;
  outline-offset: -2px;

  &:active {
    outline: 1px solid
      ${({ activeBorder, theme }) => theme.colors[activeBorder]};
    border: 1px solid ${({ activeBorder, theme }) => theme.colors[activeBorder]};
    background: ${({ activeBackground, theme }) =>
      theme.colors[activeBackground]};
  }

  @media ${tablet} {
    display: flex;
    width: 10rem;
    height: 10rem;
    gap: 1em;
    flex-shrink: 0;
    font-size: 0.99406rem;
  }

  svg {
    width: 6.70656rem;
    height: 6.70656rem;

    @media ${tablet} {
      width: 5rem;
      height: 5rem;
    }
  }
`;

const OtherGenderButton = styled(GenderButton)`
  flex-basis: calc(33% - 1rem);
  background-color: #f3f3f8;

  border: 1px solid #8e909a;
  div p {
    text-align: center;
    color: #8e909a;
  }
  @media ${tablet} {
    flex-basis: calc(100% - 1rem);
    margin-top: 1rem;
  }
`;

const ButtonsTitleContainer = styled.div`
  text-align: center;
  @media ${tablet} {
  }
`;

const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 28.75rem;
  gap: 1.5rem;
  padding: 3rem 0;
  z-index: 10;

  @media ${tablet} {
    padding: 1.5rem;
  }
`;

const GenderContainer = styled.div`
  display: grid;
  gap: 1rem;
`;

const GenderButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media ${tablet} {
    justify-content: center;
    gap: 0.75rem;
  }
`;

const LandingTextContainer = styled.div`
  display: grid;
  gap: 1.5rem;
`;

const FooterContainer = styled.div`
  margin-top: auto;
  z-index: 99;
`;

const BackgroundImageContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  filter: blur(1px);

  svg {
    width: 100%;
  }
`;
