import { Seo } from 'components';
import React, { useRef } from 'react';
import Landing from './start/quiz/components/Landing';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { Quiz, QuizAnswer, Quiz as QuizType } from 'types/quiz';
import { updateQuizAnswers } from 'state/user/effects';
import { useRouter } from 'apis/history';
import Landing2 from './start/quiz/components/Landing2';
import theme from 'utils/theme';
import styled from 'styled-components';
import { mobile, useQuery } from 'styles/breakpoints';
import StartNowButton from '../pages/start/results/components/StartNowButton';
import LCFooter from 'components/LCFooter';
import Tracking from 'utils/tracking';
import { normalizeStates } from 'utils/localization';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

enum QuestionType {
  Single = 'single',
  Multiple = 'multiple',
  MultipleImages = 'multiple_images',
  Encouragement = 'encouragement',
  Testimonial = 'testimonial',
  Info = 'info',
  Landing = 'landing',
  Landing2 = 'landing2',
  Image = 'image',
  Slider = 'slider',
  Scale = 'scale',
  Input = 'input',
  PreviousMultiple = 'previous_multiple',
  Insert = 'insert',
  Cost = 'cost',
}

const question = {
  type: 'landing',
  key: 'gender',
  title:
    'Discover your true life path and improve relationships with astrology',
  description:
    'Take a 1-minute quiz to receive your personal astrological reading to navigate your love life & personal growth.',
  label: 'Start by selecting your gender:',
  whiteFooter: true,
  options: [
    {
      label: 'Male',
      value: 'male',
      color: 'white',
      textColor: 'black',
      activeBorderColor: 'mgPrimary100',
      activeBackgroundColor: 'mgPrimary25',
    },
    {
      label: 'Female',
      value: 'female',
      color: 'white',
      textColor: 'black',
      activeBorderColor: 'mgSecondary100',
      activeBackgroundColor: 'mgSecondary25',
    },
  ],
  disclaimerParagraphs: ['© 2024 Moongrade. All rights reserved.'],
  disclaimerParagraphsPR: [
    'Disclaimer: Results may vary from person to person.',
    '© 2024 Moongrade. All rights reserved.',
  ],
  navLinks: [
    {
      title: 'Use Cases',
      url: 'https://effecto.health/cases',
    },
    {
      title: 'Our Story',
      url: 'https://effecto.health/story',
    },
    {
      title: 'Contact Us',
      url: 'https://effecto.health/contact',
    },
  ],
  socialLinks: [
    {
      svgId: 'f-reddit',
      url: 'https://www.reddit.com/r/EffectoApp',
    },
    {
      svgId: 'f-tt',
      url: 'https://tiktok.com/@effectoapp',
    },
    {
      svgId: 'f-fb',
      url: 'https://www.facebook.com/TheEffectoApp',
    },
    {
      svgId: 'f-instagram',
      url: 'https://www.instagram.com/effecto.app',
    },
  ],
};

const Home: React.FC = React.memo(() => {
  // const dispatch = useDispatch();
  // const questions = useSelector((state: AppState) => state.funnel.questions);
  // const question = questions[0];
  // const { isTablet } = useQuery();
  // const landingRef = useRef(null);
  // const { goToQuizStart } = useRouter();
  // const quiz = new URLSearchParams(location.search).get('qz') ?? QuizType.Main;
  // const q = new URLSearchParams(location.search).get('q') ?? '1';
  // const { geolocation } = useSelector((state: AppState) => state.user);
  // const { pathname, search } = useLocation();
  // const pageUrl = pathname + search;
  // const logEvent = (data: { category: string; label?: string }) => {
  //   Tracking.trackQuizQuestionAnswered({
  //     event: `Quiz question answered - ${quiz}`,
  //     category: `${q} - ${data.category}`,
  //     label: data.label || '',
  //   });
  // };

  // const handleLandingOptionSelect = (answer: QuizAnswer) => {
  //   Tracking.virtualPageView(
  //     {
  //       city: encodeURIComponent(
  //         geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
  //       ),
  //       country: geolocation?.iso_country?.toLowerCase(),
  //       state: normalizeStates(
  //         geolocation?.iso_country || '',
  //         geolocation?.iso_state || '',
  //       ),
  //       pageUrl: pageUrl,
  //       pageTitle: pathname,
  //       location: geolocation?.iso_country?.toLocaleLowerCase(),
  //     },
  //     {
  //       city: encodeURIComponent(
  //         geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
  //       ),
  //       country: geolocation?.iso_country?.toLowerCase(),
  //       state: normalizeStates(
  //         geolocation?.iso_country || '',
  //         geolocation?.iso_state || '',
  //       ),
  //       gender: answer ? answer[0] : null,
  //     },
  //   );
  //   logEvent({
  //     category: `${question.label}${
  //       question.optionsLabel ? ` - ${question.optionsLabel}` : ''
  //     }`,
  //     label: String(answer.label),
  //   });
  //   delete answer.label;
  //   dispatch(updateQuizAnswers(answer));
  //   goToQuizStart();
  // };

  const handleLandingOptionSelect = (answer: any) => {
    if (typeof window !== 'undefined') {
      // We're in the browser environment

      // Extract URL parameters using window.location.search
      const searchParams = new URLSearchParams(window.location.search);
      const params = {};
      searchParams.forEach((value, key) => {
        params[key] = value;
      });

      console.log('URL Parameters on Button Click:', params); // Debug log the URL parameters
      console.log(answer);
      // Optionally, navigate to another page with the parameters
      sessionStorage.setItem('genderAnswer', answer.label.toLowerCase());
      const queryString = new URLSearchParams(params).toString();
      navigate(`/start/?${queryString}&q=2`);
    }
  };

  return (
    <>
      <Seo />
      {/* {question?.type === QuestionType.Landing ? (
        <div ref={landingRef}> */}
      <Landing
        hideFooter
        question={question}
        onOptionSelect={handleLandingOptionSelect}
      />
      {/* </div>
      ) : (
        <Landing2
          question={question}
          onOptionSelect={handleLandingOptionSelect}
        />
      )} */}
      {/* <LCFooter
        disclaimerText={question?.disclaimerParagraphs || []}
        whiteFooter={true}
      /> */}
    </>
  );
});

Home.displayName = 'Home';

export default Home;

const StyledButton = styled(StartNowButton)<{
  bgColor?: string;
  fontColor?: string;
}>`
  width: 100%;
  max-width: 21.4375rem;
  margin: 3rem auto 0rem;
  background-color: ${({ bgColor }) => bgColor || '#000'};
  color: ${({ fontColor }) => fontColor || '#FFF'};
  @media ${mobile} {
    max-width: 100%;
    margin-top: 1.5rem;
  }
`;

const ContentWrapper = styled.div<{ $padding?: string; $bgColor?: string }>`
  background-color: ${({ $bgColor, theme }) => $bgColor || theme.colors.light0};
  padding: ${({ $padding }) => $padding || 0};
`;

const CenterContent = styled.div<{
  $width?: string;
  $maxWidth: string;
  $padding?: string;
  $bgColor?: string;
  $borderRadius?: string;
}>`
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: ${({ $bgColor }) => $bgColor || 'transparent'};
  width: ${({ $width }) => $width || 'auto'};
  max-width: ${({ $maxWidth }) => $maxWidth || 'auto'};
  padding: ${({ $padding }) => $padding || 0};
  border-radius: ${({ $borderRadius }) => $borderRadius || '16px'};
`;
