import React, { FC, forwardRef, useState } from 'react';
import styled from 'styled-components';
import Logo from 'assets/icons/logo/logo.svg';
import BurgerMenuButton from './BurgerMenuButton';
import { tablet, useQuery } from 'styles/breakpoints';
import BurgerMenu from './BurgerMenu';
import { useScrollToTop } from 'utils/hooks';
import { useRouter } from 'apis/history';

interface HeaderProps {
  isMobileApp: boolean;
  bgHex?: string;
}

const Header = forwardRef<HTMLDivElement, HeaderProps>(
  ({ isMobileApp, bgHex }, ref) => {
    const { isTablet } = useQuery();
    const { goToLanding } = useRouter();
    const [isOpen, setIsOpen] = useState(false);

    const toggleBurgerMenu = () => {
      setIsOpen(prevState => !prevState);
      document.body.style.overflow = isOpen ? 'auto' : 'hidden';
    };

    useScrollToTop(0);

    return (
      <div ref={ref}>
        <BurgerMenu isOpen={isOpen} toggleBurgerMenu={toggleBurgerMenu} />
        <Container bgHex={bgHex}>
          <HeaderWrapper alignCenter={isMobileApp}>
            {!isMobileApp ? (
              <>
                <LogoContainer
                  onClick={() => {
                    document.body.style.overflow = 'auto';
                    goToLanding();
                  }}
                >
                  <HeaderLogo />
                </LogoContainer>
                <NavContainer>
                  <BurgerMenuButton
                    isOpen={isOpen}
                    toggleBurger={toggleBurgerMenu}
                  />
                </NavContainer>
              </>
            ) : (
              <LogoContainer>
                <HeaderLogo />
              </LogoContainer>
            )}
          </HeaderWrapper>
        </Container>
      </div>
    );
  }
);

export default Header;

const Container = styled.div<{ bgHex?: string }>`
  background: ${({ bgHex }) => (bgHex ? bgHex : '#fff')};
  border-bottom: 1px solid #f7f7f7;
  padding: 0 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  width: 100%;
  z-index: 99;
  top: 0;
  height: 4.5rem;
  box-shadow: 0px 2px 4px 0px #0000000d;

  @media ${tablet} {
    height: 4rem;
    padding: 0 1rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  width: fit-content;
  justify-content: flex-start;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media ${tablet} {
    width: fit-content;
    justify-content: center;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const HeaderLogo = styled(Logo)`
  width: 6.5rem;
`;

const HeaderWrapper = styled.div<{ alignCenter?: boolean }>`
  position: relative;
  display: flex;
  justify-content: ${({ alignCenter }) =>
    alignCenter ? 'center' : 'space-between'};
  align-items: center;
  width: 100%;
  max-width: 84rem;
  min-height: 24px;
`;
