import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { Text } from 'components';
import ReactCountdown from 'react-countdown';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { getLocalisedProduct } from 'utils/localization';

interface HeaderCountdownProps {
  isPaymentComponentVisible: boolean;
  isSpecial: boolean;
}

export const HeaderCountdown: FC<HeaderCountdownProps> = ({
  isPaymentComponentVisible,
  isSpecial,
}) => {
  const countdownDate = useMemo(() => Date.now() + 900000, []);
  const { isMobile } = useQuery();
  const renderer = ({
    formatted = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    },
  }) => (
    <ReactCountdownContainer>
      <CountdownUnitsContainer>
        <Time isWhite={isPaymentComponentVisible}>{formatted.minutes}</Time>
      </CountdownUnitsContainer>
      <Colon isWhite={isPaymentComponentVisible}>:</Colon>
      <CountdownUnitsContainer>
        <Time isWhite={isPaymentComponentVisible}>{formatted.seconds}</Time>
      </CountdownUnitsContainer>
    </ReactCountdownContainer>
  );

  return (
    <HeaderCountDown isPaymentComponentVisible={isPaymentComponentVisible}>
      {isPaymentComponentVisible ? (
        <CountdownTitlePayment>The offer expires in:</CountdownTitlePayment>
      ) : (
        <CountdownTitle>
          {isSpecial ? '71' : '51'}% discount reserved for:
        </CountdownTitle>
      )}
      <ReactCountdown date={countdownDate} renderer={renderer} />
    </HeaderCountDown>
  );
};

const CountdownTitlePayment = styled.p`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const HeaderCountDown = styled.div<{ isPaymentComponentVisible: boolean }>`
  ${({ isPaymentComponentVisible }) =>
    isPaymentComponentVisible &&
    'display: flex;justify-content: space-between;align-items: center;width: 100%;'};
`;

const ReactCountdownContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CountdownUnitsContainer = styled.div<{ hasMarginLeft?: boolean }>`
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: ${({ hasMarginLeft }) => (hasMarginLeft ? '0.625rem' : '0')};
`;

const Time = styled(Text)<{ isWhite?: boolean }>`
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Afacad", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};
`;

const Colon = styled(Time)`
  margin: 0 3px;
  font-weight: 900;
`;

const CountdownTitle = styled.p`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
