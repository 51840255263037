import React, {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  useMemo,
} from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import type { Color } from 'utils/theme';
import { CircularProgress } from '@material-ui/core';

interface PrimaryButtonProps
  extends PropsWithChildren<ComponentPropsWithoutRef<'button'>> {
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
  cornerVariant?: 'default' | 'round';
  minHeight?: number;
  color?: Color;
  colorActive?: Color;
  fontSize?: number;
  fontFamily?: string;
  fontColor?: string;
  loading?: boolean;
  upperText?: string;
  hasBackground: boolean;
}

const Container = styled(ButtonContainer)<{
  borderRadius: number;
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
  minHeight?: number;
  color: Color;
  colorActive: Color;
}>`
  display: flex;
  gap: 0.75rem;
  border: none;
  height: ${({ minHeight }) => (minHeight ? minHeight : 3)}rem;
  padding: ${({ minHeight }) => (minHeight && minHeight < 3 ? 0 : 0.66)}rem;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${({ theme, active, color, colorActive }) =>
      theme.colors[active ? colorActive : color]};
  border-radius: ${({ borderRadius }) => borderRadius}rem;
  background-color: ${({ theme, active, color, colorActive }) =>
    theme.colors[active ? colorActive : color]};

  &:disabled {
    background-color: ${({ color }) => color};
    cursor: default;
  }

  transition: 0.15s;
`;

const ButtonText = styled(Text).attrs({
  type: 'body800',
})<{
  fontSize?: number;
  fontFamily?: string;
  fontColor?: string;
  disabled?: boolean;
  loading?: boolean;
}>`
  color: ${({ fontColor }) => (fontColor ? fontColor : '#000')};
  ${({ loading, disabled, fontColor, theme }) =>
    (loading || disabled) && `${fontColor ? fontColor : theme.colors.black}`};
  font-size: ${({ fontSize }) => fontSize || '1.25rem'};
  font-weight: 700;
  line-height: normal;
  font-family: "Afacad", sans-serif;
`;

const CircularProgressStyled = styled(CircularProgress)``;

const PrimaryButton: FC<PrimaryButtonProps> = ({
  onClick,
  children,
  active,
  disabled,
  cornerVariant = 'round',
  minHeight,
  color = 'primary',
  colorActive = 'primaryDark',
  fontSize,
  fontFamily,
  fontColor,
  loading,
  ...props
}) => {
  const borderRadius = useMemo(
    () => (cornerVariant === 'round' ? 10 : 0.5),
    [cornerVariant]
  );

  return (
    <Container
      onClick={onClick}
      as="button"
      borderRadius={borderRadius}
      active={active}
      disabled={disabled}
      loading={loading}
      minHeight={minHeight}
      color={color}
      colorActive={colorActive}
      {...props}
    >
      <ButtonText
        fontColor={fontColor}
        fontSize={fontSize}
        fontFamily={fontFamily}
        disabled={disabled}
        loading={loading}
      >
        {children}
      </ButtonText>
      {loading && (
        <CircularProgressStyled style={{ color: '#000' }} size={20} />
      )}
    </Container>
  );
};

export default React.memo(PrimaryButton);
