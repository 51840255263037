import React from 'react';
import styled from 'styled-components';

import { tints, white } from 'styles/colors';

interface Props {
  onClose: () => void;
}

export const ModalWrapper: React.FC<Props> = ({ children, onClose }) => (
  <ModalOverlay onClick={onClose}>
    <ModalView onClick={event => event.stopPropagation()}>
      {children}
    </ModalView>
  </ModalOverlay>
);

export const ModalOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  padding: 4.5rem 0;
  min-height: 100vh;
  height: 100%;
  overflow: auto;
  background: ${tints.black(6)};
`;

export const ModalView = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;
  max-height: calc(100% - 4rem);
  padding: 40px 4% 66px;
  margin: 2rem;
  background: ${white};
  border-radius: 16px;
  overflow: scroll;
  z-index: 999;
`;
