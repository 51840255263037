import { Color } from 'utils/theme';

export enum Quiz {
  Main = 'main-mg',
  BreedsList = 'dogs-breed-list',
}

export enum QuizKey {
  Qz = 'qz',
}

interface BirthDate {
  date: Date | string;
  zodiac_sign: string;
}

interface TimeOfBirth {
  hours: string;
  minutes: string;
  ampm: string;
}

interface PlaceOfBirth {
  full_text: string;
  input_text: string;
  lat: number;
  lng: number;
  main_text: string;
  place_id: string;
  secondary_text: string;
}

export interface QuizAnswer {
  birth_date: BirthDate;
  birth_time: TimeOfBirth;
  birth_place: PlaceOfBirth;
  partner_birth_date: BirthDate;
  partner_birth_time: TimeOfBirth;
  partner_birth_place: PlaceOfBirth;
  gender: string;
  partner_gender: string;
  relationship_status: string;
  [key: string]:
    | string
    | boolean
    | number
    | object
    | BirthDate
    | TimeOfBirth
    | PlaceOfBirth;
}

export interface QuestionOption {
  label: string;
  value: string;
  score: string;
  maxHeight?: string;
  isDogWordDynamic?: boolean;
  color?: Color;
  image?: string;
  emoji: string;
  custom: {
    icon: string;
    deselectAll?: boolean;
    horizontal?: boolean;
    disabled?: string;
  };
}

interface Image {
  desktopImg?: string;
  mobileImg?: string;
  alt?: string;
}

export type Question = {
  type: string;
  key: string;
  label: string;
  colorLabel: string;
  title?: string;
  topLabel?: string;
  desktopImg?: string;
  mobileImg?: string;
  image?: string;
  alt: string;
  iconsOptions?: string[];
  mdpiImage?: Image;
  description?: string;
  descriptionIsMaxWidth?: boolean;
  paragraphs?: string[];
  navLinks?: { url: string; title: string }[];
  socialLinks?: { svgId: string; url: string }[];
  disclaimerParagraphs?: string[];
  options?: QuestionOption[] | object;
  accept?: Array<string>;
  buttons?: string[];
  hasBackground?: boolean;
} & Record<string, string>;

export type Unit = 'metric' | 'imperial';
