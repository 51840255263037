import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ProjectConfigResponse } from '@frontend/api-wrapper';
import axios from 'axios';

import { config } from 'config';
import { getLocalisedIsoCountry } from 'utils/localization';
import { ApiBase } from 'apis/api';
import { handleError } from 'utils/error';

import { setPaymentGateway } from './actions';

export const fetchPaymentGateway =
  () => async (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    try {
      const ApiBase = axios.create({
        baseURL: config.BASE_URL,
        params: {
          api_token: config.API_TOKEN,
          p_locale: getLocalisedIsoCountry().toLowerCase(),
        },
      });
      const response = await ApiBase.get<{ data: ProjectConfigResponse }>(
        '/project'
      );
      dispatch(setPaymentGateway(response.data.data.gateway || null));
    } catch (e) {
      handleError(e as Error);
    }
  };
